import * as React from "react";
import { Global } from "@emotion/react";
import { GlobalStyles as BaseStyles } from "twin.macro";
import SEO from "./sections/SEO";
import { customStyles } from "./page.styles";
import { StaticImage } from "gatsby-plugin-image";

const Heading = ({ children }: any) => (
  <h2 tw="text-xl font-bold pb-1">{children}</h2>
);
const Text = ({ children }: any) => (
  <p tw="pb-2 max-w-xl xl:max-w-3xl">{children}</p>
);

const TextContainer = ({ children }: any) => <div tw="pb-6">{children}</div>;

const IndexPage = () => {
  return (
    <>
      <BaseStyles />
      <Global styles={customStyles} />
      <SEO />
      <main tw="container flex flex-col items-center p-8 mx-auto">
        <div tw="flex items-center pb-8">
          <div tw="mr-4 sm:mr-8">
            <StaticImage
              src="../images/me.png"
              alt="João"
              placeholder="blurred"
              layout="fixed"
              height={250}
            />
          </div>
          <h1 tw="text-2xl sm:text-3xl">
            Hi!
            <span tw="animate-wave origin-[70%_70%] inline-block">👋</span> I'm
            João,
            <br />
            <strong>Fullstack Developer</strong>
            <br />
            based out of Lisbon, Portugal.
          </h1>
        </div>
        <div tw="mx-auto">
          <TextContainer>
            <Heading>SKILLS</Heading>
            <Text>
              <strong>Programming:</strong> Javascript, NodeJs, React, React
              Native, NextJS, Storybook, CSS3, HTML5
            </Text>
            <Text>
              <strong>Databases:</strong> Mongodb, Postgresql, Mysql, Redis
            </Text>
            <Text>
              <strong>Services:</strong> AWS, Heroku, Vercel, Github,
              Cloudinary, Contenful, Rest, Graphql
            </Text>
            <Text>
              <strong>Tools:</strong> Git, Trello, Slack, VSCode, Google
              Docs/Spreadsheets
            </Text>
          </TextContainer>
          <TextContainer>
            <Heading>RECENT WORK</Heading>
            <a
              href="https://app.perfectdays.in/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              🔗&nbsp;Perfect&nbsp;Days
            </a>
            &nbsp;&nbsp;
            <a
              href="https://surfngaru.pt/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              🔗&nbsp;Surf&nbsp;Ngaru
            </a>
          </TextContainer>
          <TextContainer>
            <Heading>HOBBIES</Heading>
            <p>🏄🏽‍♂️&nbsp;Surf 🛹&nbsp;Skate 🤹🏽‍♂️&nbsp;Juggling</p>
          </TextContainer>
          <TextContainer>
            <Heading>CONTACT ME</Heading>
            <a href="mailto:me@joaogomes.work">✉️&nbsp;me@joaogomes.work</a>
          </TextContainer>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
